<template>
  <v-card-text>
    <v-row>
      <v-col
          sm="6"
          cols="12"
          v-if="update === '1'"
      >
        <v-text-field
            dense
            v-model="deposit_data.tx_id"
            label="tx_id"
            :disabled="update === '1'"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="deposit_data.amount"
            label="amount"
            :rules="RequiredRules"
            :disabled="update === '1'"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
          v-if="update !== '1'"
      >
        <v-file-input
            dense
            accept="image/*"
            label="Deposit Image"
            :rules="RequiredRules"
            @change="cprofile_pic"
        ></v-file-input>
      </v-col>
      <v-col
          sm="6"
          cols="12"
          v-if="update === '1'"
      >
        <v-autocomplete
            dense
            v-model="deposit_data.status"
            label="Status"
            :items="status_list"
            clearable
            :rules="[v => !!v || 'Status must filled']"
        ></v-autocomplete>
      </v-col>
      <v-img v-bind:src="deposit_data.img" style="max-width: 100%" alt="deposit image"/>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "CategoryFormComponent",
  data: () => ({
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
    imageBytes1: '',
  }),
  props: {
    deposit_data: Object,
    update: String,
    status_list: [],
  },
  methods: {
    cprofile_pic(e){
      let file = e
      let reader = new FileReader()
      if( file['size'] < 5111775 ){
        reader.onloadend = (file) => {
          console.log('file', file)
          this.deposit_data.img = reader.result;
        }
        reader.readAsDataURL(file)
      }else{
        this.form.profile_pic = ''
        alert( 'File maximal 2MB');
      }
    },
  }
}
</script>

<style scoped>

</style>
